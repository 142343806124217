@import "../../assets/theme/constants.scss";

.sectionCard {
  margin-top: 45px;
  border-radius: 5px;
  background-color: $bgColorSidebar;
  padding-bottom: 40px;
  height: calc(100vh - 160px);

  .sectionCardHeader {
    display: flex;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid;

    .title {
      color: $white;
    }
  }

  .sectionCardBody {
    height: calc(100vh - 260px);
    overflow-y: scroll;
    padding-top: 1.5rem;
    margin: 1rem 2rem;

    .stepperRoot {
      margin-top: 40px;
      border-bottom: 2px solid $ternary;
    }
  }

  .saveAs {
    padding: 30px 100px;
    border-bottom: 2px solid $ternary;

    .switchField {
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
      }
    }
  }

  .sectionCardFooter {
    display: flex;
    justify-content: flex-end;
    padding: 0px 45px;
    margin-bottom: 10px;
  }
}
