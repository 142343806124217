@import "../../../assets/theme/constants.scss";

.viewSummaryCard {
    background-color: $bgColorSidebar !important;
    border-radius: 25px !important;

    .viewSummaryCardDataRow {
        display: flex;
        align-items: center;

        .data {
            font-weight: 400;
            font-size: 18px;
            color: $white;
        }

        .action {
            background-color: #121212;
            padding: 5px 10px;
            display: flex;
            align-items: center;

            .text {
                font-size: 10px;
                text-transform: none;
                color: #bfbfbf;
            }

            .icon {
                font-size: 15px;
            }
        }
    }
}