
.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0px;
}

.active {
    color: #00C853;
}

.inactive {
    color: white;
}