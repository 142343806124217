@import "../../assets/theme/constants.scss";

.sectionCard {
  margin-top: 45px;
  border-radius: 5px;
  background-color: $bgColorSidebar;
  padding-bottom: 40px;
  height: calc(100vh - 160px);

  .sectionCardHeader {
    display: flex;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid;

    .title {
      color: $white;
    }
  }

  .sectionCardBody {
    height: calc(100vh - 230px);
    overflow-y: scroll;

    .stepperRoot {
      margin-top: 40px;
      border-bottom: 2px solid $ternary;
    }
  }

  .sectionCardFooter {
    display: flex;
    justify-content: flex-end;
    padding: 30px 45px;
  }
}