@import "../../../assets/theme/constants.scss";

.action {
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 20px 0px;
}
.gridSection {
    
    .grid::-webkit-scrollbar-track {
        background-color: $bgColorSidebar !important;
    }
    .grid::-webkit-scrollbar-thumb {
        background-color: rgb(146, 146, 146) !important;
    }  
    .table {
        width: max-content !important;
    }
}