.gridBox
{
    margin:0 0 1rem 0;
}
.formBox{
    display: grid !important;
}
.grid{
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}.label {
    margin-bottom: 1rem;
}
.label2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}