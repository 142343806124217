@import "../../../assets/theme/constants.scss";

.summaryCard {
  max-height: 20rem !important;
  background-color: $bgColorSidebar !important;
  padding: 1px 20px;
  border-radius: 25px !important;

  .summaryCardTitle {
    display: flex;
    align-items: end;

    .main {
      font-weight: 400;
      font-size: 18px;
      color: $white;
    }
    .sub {
      color: #bfbfbf;
    }
  }
}
@media (max-width: 1800px) {
  .summaryCard {
    height: 24rem !important;
  }
}
@media (max-width: 1546px) {
  .summaryCard {
    height: auto !important;
  }
}
