.taskContainer{    

    padding: 10px;
    margin-bottom: 10px;
    
}
.border{
  border: 2px solid #09072a;
  color:#09072a;
  background-color: #eba02b;

}
.taskName{
    font-weight: bold;
 margin-right: 1rem;
}
.taskDescription{
    margin-top: 5px;
    padding-top: 1rem;
  }
  
  .taskTime{
    position: relative;
    margin-top: 5px;
    padding-top: 1rem;
    right: 0;
    
  
  }
  .DataBox{
    justify-content: space-between;
    display: flex;
  cursor: pointer;

  }
  .loading{
    padding-top: 2rem;
    font-weight: 500;
    text-align: center;
    justify-content: center; 
    justify-items: center;
  }
  