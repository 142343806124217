@import "../../../assets/theme/constants.scss";

.summaryCard {
  background-color: $bgColorSidebar !important;
  padding: 10px 20px;
  height: 24rem !important ;
  border-radius: 25px !important;
  

  .summaryCardTitle {
    display: flex;
    align-items: end;

    .main {
      font-weight: 400;
      font-size: 1rem;
      color: $white;
    }
    .sub {
      color: #bfbfbf;
      font-size: 0.6rem;
    }
  }
}

.apexcharts-bar-area:hover {
  fill: #FF0099;
}

@media (max-width: 1800px) {
  .summaryCard {
    height: 25.5rem !important;
  }
}
@media (max-width: 1546px) {
  .summaryCard {
    height: auto !important;
  }
}
