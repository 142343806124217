@import "../../../assets/theme/constants.scss";

.switchField {
  display: flex;
  align-items: center;

  .label {
    margin-right: 2rem;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0px;
}

.someone {
  padding: 0px 0px 10px 0px;
  border-bottom: 2px solid $ternary;
  margin-bottom: 30px;
}

.gridBox
{
    margin:0 0 1rem 0;
}
.formBox{
    display: grid !important;
}
.grid{
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}.label {
    margin-bottom: 1rem;
}
.label2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.instructionSection{
  margin-bottom: 30px;

  .instruction {
      font-size: 14px;
      color: #6b6b6b;
  }
}
