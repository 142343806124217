.gridBox
{
    margin:0 0 1rem 0;
}
.formBox{
    display: grid !important;
}
.gridSection {
    margin-bottom: 1.5rem;
}
.grid{
    margin-top: 2rem;
}.label {
    margin-bottom: 1rem;
}
.label2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.switchField {
    padding-top: 4rem;
    display: flex;
    align-items: center;
  
    .label {
      margin-right: 10px;
    }
  }
  .HPDetails{
    text-decoration: underline;
    padding-left: 15%;
    padding-top: 4.2rem;
    cursor: pointer ;

  }