@import "../../../assets/theme/constants.scss";

.viewSummaryCard {
  background-color: $bgColorSidebar !important;
  border-radius: 25px !important;

  .viewSummaryCardDataRow {
    display: flex;
    align-items: center;

    .data {
      font-weight: 400;
      font-size: 14px;
      color: $white;
    }
  }
}