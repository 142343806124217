@import "../../../assets/theme/constants.scss";

.sectionCard {
  margin-top: 45px;
  border-radius: 5px;
  background-color: $bgColorSidebar;
  padding-bottom: 40px;
  height: calc(100vh - 160px);

  .sectionCardHeader {
    display: flex;
    padding: 30px 50px;
    align-items: center;
    border-bottom: 1px solid;

    .title {
      color: $white;
    }
  }

  .sectionCardBody {
    height: calc(100vh - 260px);
    overflow-y: scroll;

    .vehicleSection {
      padding: 10px 10px;
      // border-bottom: 2px solid $ternary;
    }

    .incidentSection {
      padding: 20px 1px;
      // border-bottom: 2px solid $ternary;

      .itemsAction {
         display: flex;
         justify-content: flex-end;
         margin-top: 20px;
         margin-bottom: 20px;
      }
    }
  }

  .sectionCardFooter {
    border-top: 2px solid $ternary;
    display: flex;
    justify-content: flex-end;
    padding: 30px 50px;
  }
}
.customContainer {
  width: 100%;
}

.underline {
  text-decoration: underline;
}