.customContainer {
    width: 100%;
  }

.helperText {
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
  color: red;
}

