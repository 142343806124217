.label {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.label2 {
  margin-block: 1rem;
}
.lable3 {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
}
