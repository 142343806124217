@import "../../assets/theme/constants.scss";

.sectionCard {
  margin-top: 45px;
  border-radius: 5px;
  background-color: $bgColorSidebar;
  padding-bottom: 40px;
  height: calc(100vh - 160px);

  .sectionCardHeader {
    display: flex;
    padding: 20px;
    align-items: center;
    font-size: 150%;

    .title {
      color: $white;
    }
  }

  .sectionCardBody {
    height: calc(100vh - 260px);
    overflow-y: scroll;
    margin: auto 5vw auto auto;
  }

  .saveAs {
    padding: 30px 100px;
    border-bottom: 2px solid $ternary;

    .switchField {
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
      }
    }
  }

  .sectionCardFooter {
    display: flex;
    justify-content: end;
    padding: 30px 45px;
  }
}

.templates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5em;
  margin: auto 10vw 1rem 2rem;
}

@media (max-width: 768px) {
  .templates {
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .templates {
    justify-content: flex-start;
  }
  .sectionCardBody {
    /* Adjust the styles for smaller screens */
  }
}
