@import "../../../assets/theme/constants.scss";

.suggestionsCard {
    background-color: #2f2f2f;
    border: 1px solid #3a3a3a;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    width: 15rem;
    align-items: center;
    justify-content: center;

    .rout {
        font-size: 12px;
        margin-bottom: 10px;
    }
    
    .time {
        margin-bottom: 10px;
        font-weight: 500;
    }
    
    .seats {
        font-size: 12px;
    }
}
.suggestionsCardActive {
    background-color: #2f2f2f;
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 20px;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .rout {
        font-size: 12px;
        margin-bottom: 10px;
    }
    
    .time {
        margin-bottom: 10px;
        font-weight: 500;
    }
    
    .seats {
        font-size: 12px;
    }
}