@import "../../assets/theme/constants.scss";

.sectionCard {
  margin-top: 45px;
  border-radius: 5px;
  background-color: $bgColorSidebar;
  padding-bottom: 40px;
  height: calc(100vh - 160px);

  .sectionCardHeader {
    display: flex;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid;

    .title {
      color: $white;
    }
  }

  .sectionCardBody {
    height: calc(100vh - 260px);
    overflow-y: scroll;

    .stepperRoot {
      margin-top: 40px;
      border-bottom: 2px solid $ternary;
    }
  }

  .saveAs {
    padding: 30px 100px;
    border-bottom: 2px solid $ternary;

    .switchField {
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
      }
    }
  }

  .sectionCardFooter {
    display: flex;
    justify-content: flex-end;
    padding: 30px 45px;
  }
}

.gridBox
{
    margin:0 0 1rem 0;
}
.formBox{
    display: grid !important;
}
.grid{
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}.label {
    margin-bottom: 1rem;
}
.label2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}