@import "../../../assets/theme/constants.scss";

* {
  margin: 0;
  padding: 0;
}

.box {
  background: rgb(30, 30, 30);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  border: 1px solid $bgIsActive; /* Add a border */
  text-align: left;
  border-radius: 0.5rem;
  padding: 1rem;
}

.main {
  grid-column: span 2;
}

.design {
  grid-column: span 1;
  text-align: center;
}

.overview {
  margin-top: 0.5rem;
  grid-column: span 3;
}

.passengers {
  grid-column: span 3;
  font-size: 0.8em;
}

.passengerListContainer {
  display: flex;
  flex-wrap: wrap;
}

.passengerItem {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.check1 {
  display: flex;
  align-items: center;
}

.passengerName {
  margin: 0;
}
