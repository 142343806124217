@import "../../../assets/theme/constants.scss";

.StyledTextField::after {
    content: ".00";
    position: absolute;
    right: 10px; /* Adjust the positioning as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the pseudo-element is not interactive */
    color: white; /* Adjust the color as needed */
}

.background{
    background-color: $bgColorSidebar;
    border-radius: 1rem;
    padding: 5rem;
}