@import "../../assets/theme/constants.scss";

.switchField {
  display: flex;
  align-items: center;

  .label {
    margin-right: 10px;
  }
}

.someone {
  padding: 0px 0px 10px 0px;
  border-bottom: 2px solid $ternary;
  margin-bottom: 30px;
}
.switchField2 {
  padding-left: 2rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;

  .label2 {
    margin-right: 10px;
  }
}
