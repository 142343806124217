@import "../../assets/theme/constants.scss";

.container {
  // background-color: #F6F7FB;
  position: fixed;
  background: url("../../assets/images/bg/login-bg-style-1.jpg");

  overflow: scroll;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: grid;

  .login {
    margin: 24px 0;
    display: grid;

    .logincard {
      border: 1px solid #555555;
      border-radius: 14px;
      padding: 0 24px;
      width: 460px;
      height: 500px;
      margin: 0 auto;
      background-color: $bgColorSidebar;

      @media all and (max-width: $s) {
        width: 80%;
      }

      .loginActions {
        padding: 10px 0 10px;

        text-align: center;

        @media all and (max-width: 540px) {
          width: auto;
        }

        .logo {
          max-width: 75px;
          padding: 15px 0px;
          text-align: center;

          @media all and (max-width: $s) {
            padding: 20px 0 0px;
          }
        }

        h1 {
          border-bottom: 1px solid #555555;

          color: #818286;
          padding-bottom: 20px;
          // padding: 10px 0;
          // font-size: 20px;
          font-weight: 500;
          vertical-align: middle;
          margin: 8px 0;

          font-size: 20px;
        }

        p {
          padding-top: 15px;
          border-top: 1px solid #555555;
          line-height: 20px;
          font-size: 14px;
          margin-top: 80px;
          color: #818286;
        }

        .loading {
          color: white;
          margin-right: 10px;
        }

        .circular {
          color: white;
        }

        .loginNotificationnew {
          background-color: red;
        }

        .loginNotification {
          margin-top: 60px;

          p {
            font-size: 13px;
            display: block;
            padding: 3px 8px;

            a {
              text-decoration: underline !important;
            }
          }

          .isAuthorizing {
            //background: rgba(0,150,255,0.3);
            background: #fff8cb;
          }

          .error {
            padding: 4px 8px;
            background: #fff8cb;
            padding: 10px;
            border-radius: 6px;
            margin: 14px 0;
            width: 400px;
          }
        }

        .loginFooter {
          font-size: 14px;
          color: #838282;
        }

        .primaryBtn {
          width: 50%;
          margin-top: 14px;
          font-weight: 600;
          padding-top: 14px;
          margin-right: 10px;
          margin-left: 10px;
          padding-bottom: 14px;
          margin-top: 60px;
          margin-bottom: 35px;
          background: $red;
          color: white;
          margin-bottom: 0.4em !important;
          font-size: 10px;
          text-transform: capitalize !important;
          &.disabled {
           background: $gray !important; 
           color: black
          }
        }

        .secondaryBtn {
          width: 50%;
          margin-top: 14px;
          font-weight: 600;
          padding-top: 14px;
          margin-right: 10px;
          margin-left: 10px;
          padding-bottom: 14px;
          margin-top: 60px;
          margin-bottom: 35px;
          background: $ternary;
          color: white;
          border: 1px solid white;
          margin-bottom: 0.4em !important;
          font-size: 10px;
          text-transform: capitalize !important;
          &.disabled {
           background: $gray !important; 
           color: black
          }
        }

        .primaryBtn:disabled {
          background-color: #ccc;
          color: #999;
        }
      }
    }
  }
}

.alertMargin {
  margin-bottom: 10px;
  background-color: red;
}
