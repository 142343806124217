@import "../../../assets/theme/constants.scss";

.dialogCard {
  align-items: center !important;
  justify-items: center !important;
  text-align: left !important;
}

.table {
  align-items: center !important;
  text-align: center !important;
}
