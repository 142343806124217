@import "../../assets/theme/constants.scss";
.root{
  padding: 1rem;
}
.switchField {
  display: flex;
  align-items: center;

  .label {
    margin-right: 10px;
  }
}

.MuiSvgIcon-root {
  color: white;
}

/* autocomplete.css */
.MuiAutocomplete-popupIndicator svg {
  color: rgb(107, 107, 107);
}

.dataset {
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: #09072a;
}

.column {
  padding: 10px;
  background-color: #09072a;
}
