@import "../../../assets/theme/constants.scss";

.dialogCard {
  align-items: center !important;
  justify-items: center !important;
  text-align: center !important;
}

.bodyContent {
  padding: 40px 0px 80px 0px !important;
  overflow-x: scroll;
  display: flex;

  .stepperRow {
    display: flex;
    align-items: center;
    padding-left: 50px;

    .status {
      position: relative;

      .stepCircle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #00c853;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepInnerCircle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $bgColorSidebar;
        }
      }

      .stepContent {
        width: 195px;
        padding: 5px;
        position: absolute;
        bottom: -55px;
        left: -70px;

        .statusName {
          margin-bottom: 10px;
        }

        .date {
          color: #5f5f5f;
        }
      }
    }

    .horizontalLine {
      width: 250px;
      border-bottom: 3px solid #00c853;
    }
  }

}