@import "../../assets/theme/constants.scss";



.gridContainer {
    margin: 45px;
    border-radius: 5px;
    background-color: $bgColorSidebar;
    padding-bottom: 40px;

 
    .Profile{
      justify-content: center;
      justify-items: center;
      align-items: center;
  
    }
    .textBox{
        margin-top: 1rem;
        .name{
            font-size: 1.8rem;
        }
        .designation{
            font-size: 1.2rem;
        }.Description{
            padding-top: 0.5rem;
            font-size: 0.8rem;
        }
    }
    .profileimg{
        width: 8rem;
        height: 8rem;
        border: 0.8rem solid rgba(255, 0, 0, 0.1);
    }
}
.verticalline {
    width: 2px;
    height: 100px;
    background-color: white;
  }
  .css-mhc70k-MuiGrid-root{
    margin-left: 0 !important;
  }