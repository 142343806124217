.dialogCard {
    align-items: center !important;
    justify-items: center !important;
    text-align: center !important;
}

.items {
   margin-top: 20px;
   border-top: 2px solid #323232;
   padding-top: 20px;
}

.itemsAction {
   display: flex;
   justify-content: flex-end;
   margin-top: 20px;
   margin-bottom: 20px;
}
.gridSection{
   margin-bottom:1rem;
}