@import "../../../assets/theme/constants.scss";

.switchField {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .label {
    margin-right: 10px;
  }
}
