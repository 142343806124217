.StyledTextField::after {
    content: ".00";
    position: absolute;
    right: 10px; /* Adjust the positioning as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the pseudo-element is not interactive */
    color: white; /* Adjust the color as needed */
}

.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0px;
}

.active {
    color: #00C853;
}

.inactive {
    color: white;
}